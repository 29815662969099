@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-size: 0.8rem;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

.sidebar .active {
  font-weight: bold;
  @apply text-leechiuOrange;
}

h1 {
  @apply text-sm font-bold text-leechiuBlue uppercase;
}

.leasing .active {
  @apply font-bold border-b-2 border-leechiuOrange;
}

.billing-list .active {
  @apply bg-leechiuOrange text-white;
}

.discussion a {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display: block;
}

.listingview .active {
  @apply bg-orange-500 text-white;
}

.bubble {
  @apply bg-white p-2 rounded-xl;
}

.first-letter::first-letter {
  text-transform: uppercase;
}

.bubble {
  @apply bg-white p-2 rounded-xl;
}

.header {
  @apply text-sm font-bold text-leechiuBlue uppercase;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.active-pill {
  @apply px-2 py-1 rounded-full text-xs bg-leechiuOrange text-white border border-leechiuOrange w-fit cursor-pointer;
}

.inactive-pill {
  @apply px-2 py-1 rounded-full text-xs bg-gray-100 text-leechiuOrange border border-leechiuOrange w-fit cursor-pointer;
}

.blue-button {
  @apply bg-leechiuBlue text-xs font-bold px-2 py-1 text-white rounded-md;
}

.small-form {
  @apply min-w-[350px] min-h-[500px];
}

.close-button {
  @apply underline text-xs absolute top-2 right-2 text-gray-600;
}

.white-button {
  @apply bg-white border border-black text-xs font-bold px-2 py-1 text-gray-600 rounded-md;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.comment {
  @apply bg-gray-100 p-2 rounded-xl;
}

.activeLinkStyle {
  @apply bg-white  text-blue-900 shadow-lg rounded-lg font-bold p-2 cursor-pointer;
}

.inactiveLinkStyle {
  @apply text-gray-600  font-bold hover:bg-white cursor-pointer p-2 rounded-lg hover:shadow-lg;
}

.links-container {
  @apply flex items-center justify-start gap-4 bg-gray-200 rounded-lg p-1 lg:w-fit w-full border border-gray-400;
}

.page {
  @apply bg-white shadow-md rounded-lg w-full min-h-screen p-4 pt-8;
}

.active {
  @apply bg-leechiuOrange;
}

.card {
  @apply p-2 rounded-xl bg-white shadow-md;
}

.card-header {
  @apply text-xs text-gray-600 w-16 lg:w-fit bg-gray-100  px-1 rounded-full;
}